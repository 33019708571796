<template>
  <div class="service-info app-nav-wrap">
    <nav-bar current-name="服务反馈" :returnFunc="returnFunc"></nav-bar>
    <div class="app-nav-content">
      <van-form @submit="formSubmit">
        <van-field clickable readonly v-model="form.date" placeholder="选择日期" label="生产日期" @click="showDateLayer" :rules="rules.date"></van-field>
        <van-field v-model="form.address" label="所在地址" placeholder="请输入收货地址" :rules="rules.address" disabled></van-field>
        <van-field v-model="form.note" label="补充说明" placeholder="请输入补充说明" type="textarea"></van-field>
        <van-field v-model="form.imageUrl" label="反馈图" class="vertical" :rules="rules.imageUrl">
          <template #input>
            <div class="upload" @click="upload">
              <span v-if="!imageUrl">点击添加图片</span>
              <van-image :src="imageUrl" v-else></van-image>
            </div>
          </template>
        </van-field>
        <van-button class="submit">提交</van-button>
      </van-form>
    </div>
    <van-popup v-model="dateLayer.show" position="bottom">
      <van-datetime-picker v-model="dateLayer.value" type="date" :min-date="dateLayer.minDate" :max-date="dateLayer.maxDate" @confirm="confirmDate" @cancel="dateLayer.show = false"></van-datetime-picker>
    </van-popup>
  </div>
</template>

<script>
  import NavBar from '@/components/NavBar.vue';
  import CommonService from '@/io/common.js';

  export default {
    name: 'ServiceInfo',
    data() {
      return {
        dateLayer: {
          value: '',
          show: false,
          minDate: new Date('2018/1/1'),
          maxDate: new Date()
        },
        imageUrl: '',
        address: {},
        form: {
          date: `${this.currentDate().year}-${this.currentDate().month}-${this.currentDate().day}`,
          address: '',
          note: '',
          imageUrl: ''
        },
        rules: {
          date: [
            {
              required: true, message: '请选择日期'
            }
          ],
          imageUrl: [
            {
              required: true, message: '请上传图片'
            }
          ],
        }
      }
    },
    mounted () {
      this.initAddress();
    },
    components: {
      NavBar
    },
    methods: {
      initAddress() {
        this.$toast.loading({
          message: '加载中...',
          duration: 0
        });
        CommonService.serviceAddress().then(res => {
          this.address = res.data[0];
          this.form.address = res.data[0].address;
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      upload() {
        let self = this;
        wx.chooseImage({
          count: 1,
          sizeType: ['compressed'],
          sourceType: ['album', 'camera'],
          success: function (res) {
            let localIds = res.localIds;
            self.$toast.loading({
              message: '上传中...',
              duration: 0
            });
            wx.uploadImage({
              localId: localIds.toString(),
              isShowProgressTips: 1,
              success: function (res) {
                var serverId = res.serverId;
                CommonService.serviceUpload(serverId).then(res => {
                  self.imageUrl = `${process.env.VUE_APP_IMAGE_URL}${res.data.filename}`;
                  self.form.imageUrl = res.data.filename;
                }).catch(err => {}).finally(() => {
                  self.$toast.clear();
                });
              }
            });
          }
        });
      },
      returnFunc() {
        this.$emit('cancel');
      },
      formSubmit() {
        CommonService.addService(
          this.form.date,
          this.address.id,
          this.address.name,
          this.address.phone_num,
          this.address.address,
          this.address.userid,
          this.form.note,
          this.form.imageUrl
        ).then(res => {
          this.$notify({ type: 'success', message: '创建成功' });
          this.$emit('submit');
        }).catch(err => {
          
        });
      },
      showDateLayer() {
        this.dateLayer.value = new Date(this.form.date);
        this.dateLayer.show = true;
      },
      confirmDate(date) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        this.form.date = `${year}/${month}/${day}`;
        this.dateLayer.show = false;
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .upload {
    background-color: #EDEDED;
    border-radius: 14 * @r;
    width: 100%;
    height: 452 * @r;
    color: #9E9E9E;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .van-image {
      width: 100%;
      height: 100%;
      img {
        border-radius: 14 * @r;
      }
    }
  }
</style>