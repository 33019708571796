<template>
  <div class="address-info app-nav-wrap">
    <nav-bar current-name="添加地址" :returnFunc="returnFunc"></nav-bar>
    <div class="app-nav-content">
      <van-form @submit="formSubmit" ref="form">
        <van-field name="name" v-model="form.name" label="联系人" placeholder="请输入收货人姓名" :rules="rules.name"></van-field>
        <van-field name="sex" label="性别">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal">
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="phone" v-model="form.phone" label="联系电话" placeholder="请输入收货人手机号" :rules="rules.phone">
          <template #button>
            <van-button slot="button" :disabled="!!sendTime" @click.prevent="sendCode">{{sendTime || '发送验证码'}}</van-button>
          </template>
        </van-field>
        <van-field name="code" v-model="form.code" label="验证码" placeholder="请输入验证码" :rules="rules.code"></van-field>
        <!-- <van-field clickable readonly v-model="addressOne" placeholder="选择所在地区" label="所在地区" @click="showAreaLayer = true" :rules="rules.addressOne"></van-field> -->
        <van-field name="addressOne" v-model="form.addressOne" label="收货地址" disabled></van-field>
        <van-field name="addressTwo" v-model="form.addressTwo" label="详细地址" placeholder="请输入收货地址" type="textarea" :rules="rules.addressTwo"></van-field>
        <!-- <van-field label="门头照片" class="vertical">
          <template #input>
            <van-uploader class="singleLarge" v-model="form.uploader" :max-count="1" :after-read="afterRead"></van-uploader>
          </template>
        </van-field>
        <van-field input-align="right">
          <template #input>
            <van-checkbox v-model="form.checked">设置为默认地址</van-checkbox>
          </template>
        </van-field> -->
        <van-button class="submit">提交</van-button>
      </van-form>
    </div>
    <!-- <van-popup v-model="showAreaLayer" position="bottom">
      <van-area :area-list="areaList" @confirm="confirmArea" @cancel="showAreaLayer = false"></van-area>
    </van-popup> -->
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  // import areaList from '@/assets/utils/area.js';
  import NavBar from '@/components/NavBar.vue';

  export default {
    name: 'AddressInfo',
    data() {
      return {
        sendTime: 0,
        form: {
          name: '',
          sex: 1,
          phone: '',
          code: '',
          addressOne: '天津市',
          addressTwo: '',
          // uploader: [],
          // checked: false
        },
        rules: {
          name: [
            {
              required: true, message: '请填写收货人姓名'
            }
          ],
          phone: [
            {
              pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/, message: '请填写正确手机号'
            }
          ],
          code: [
            {
              required: true, message: '请填写验证码'
            }
          ],
          addressTwo: [
            {
              required: true, message: '请填写详细地址'
            }
          ]
        },
        // areaList: areaList,
        // showAreaLayer: false
      }
    },
    components: {
      NavBar
    },
    // computed: {
    //   addressOne() {
    //     return this.form.addressOne.map(val => val.name).join('/');
    //   }
    // },
    methods: {
      returnFunc() {
        this.$emit('cancel');
      },
      // confirmArea(datas) {
      //   this.form.addressOne = datas;
      //   this.showAreaLayer = false;
      // },
      // afterRead(file) {
      //   console.log(file)
      // },
      formSubmit() {
        CommonService.addAddress(
          this.form.name,
          this.form.sex,
          this.form.phone,
          this.form.code,
          this.form.addressTwo
        ).then(res => {
          this.$notify({ type: 'success', message: '创建成功' });
          this.returnFunc();
        }).catch(err => {
          
        });
      },
      sendCode() {
        this.$refs.form.validate('phone').then(res => {
          if (!this.sendTime) {
            CommonService.sendCode(this.form.phone).then(res => {
              this.$notify({ type: 'success', message: '发送成功' });
            }).catch(err => {})
            this.sendTime = 60;
            let interval = setInterval(() => {
              if (this.sendTime) {
                this.sendTime = --this.sendTime;
              } else {
                clearInterval(interval);
                interval = null;
                this.sendTime = 0;
              }
            }, 1000);
          }
        }).catch(err => {});
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .address-info {
    .iosBottom;
    .van-form {
      .vertical {
        border: none;
      }
    }
  }
</style>