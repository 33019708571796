<template>
  <div class="address app-nav-wrap">
    <nav-bar :returnFunc="returnFunc" :return-name="isLayer ? '' : '我的'" current-name="我的地址">
      <template #right>
        <p @click="showLayer = true">增加地址</p>
      </template>
    </nav-bar>
    <div class="app-nav-content">
      <div class="item" v-for="(value, index) in datas" :key="index" @click="selectItem(value)">
        <div class="top">
          <p>地址{{index + 1}}</p>
          <!-- <p>修改</p> -->
        </div>
        <div class="content">
          <!-- <span>默认地址</span> -->
          <p>联系人：{{value.name}}</p>
          <p>联系电话：{{value.phone_num}}</p>
          <p>详细地址：{{value.address}}</p>
          <!-- <p>门头照片：</p>
          <van-image src="https://bpic.588ku.com/back_list_pic/19/10/11/d29d2be7c1f74cf4c90aa5b2902cb6b5.jpg!/fw/822.85714285714/quality/90/unsharp/true/compress/true"></van-image> -->
        </div>
      </div>
    </div>
    <van-popup v-model="showLayer" position="bottom" safe-area-inset-bottom>
      <address-info @cancel="addressLayerCancel"></address-info>
    </van-popup>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import NavBar from '@/components/NavBar.vue';
  import AddressInfo from '@/views/AddressInfo.vue';

  export default {
    name: 'Address',
    props: {
      isLayer: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        showLayer: false,
        datas: []
      }
    },
    components: {
      NavBar,
      AddressInfo
    },
    mounted () {
      this.initDatas();
    },
    methods: {
      returnFunc() {
        if (this.isLayer) {
          this.$emit('cancel');
        } else {
          this.$router.push({
            path: '/user'
          });
        }
      },
      initDatas() {
        this.$toast.loading({
          message: '加载中...',
          duration: 0
        });
        CommonService.addressList().then(res => {
          this.datas = res.userinfo_list;
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      addressLayerCancel() {
        this.showLayer = false;
        this.initDatas();
      },
      selectItem(data) {
        if (this.isLayer) {
          this.$emit('select', data);
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .address {
    .iosBottom;
    .nav-bar {
      .right-region {
        p {
          background-color: @color-primary;
          color: #FFFFFF;
          font-size: 22 * @r;
          width: 126 * @r;
          height: 42 * @r;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 21 * @r;
        }
      }
    }
    .item {
      background-color: #FFFFFF;
      box-shadow: 0 2 * @r 24 * @r 0 rgba(0, 0, 0, .08);
      margin: 30 * @r 44 * @r;
      border-radius: 16 * @r;
      .top {
        background-color: @color-primary;
        height: 84 * @r;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 42 * @r;
        border-top-left-radius: 16 * @r;
        border-top-right-radius: 16 * @r;
        p {
          color: #FFFFFF;
          &:nth-of-type(1) {
            font-size: 28 * @r;
            font-weight: bold;
          }
          &:nth-of-type(2) {
            width: 76 * @r;
            height: 40 * @r;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22 * @r;
            border: 1px solid #FFFFFF;
            border-radius: 20 * @r;
          }
        }
      }
      .content {
        padding: 22 * @r 60 * @r 40 * @r 60 * @r;
        position: relative;
        & > * {
          margin-top: 18 * @r;
        }
        & > p {
          font-size: 26 * @r;
        }
        & > span {
          position: absolute;
          top: 40 * @r;
          right: 34 * @r;
          font-size: 22 * @r;
          color: @color-primary;
          margin: 0;
        }
        /deep/ .van-image {
          border-radius: 8 * @r;
          height: 206 * @r;
          img {
            border-radius: 8 * @r;
          }
        }
      }
    }
  }
  .van-popup {
    height: 100%;
  }
</style>