<template>
  <div class="coupon app-nav-wrap">
    <nav-bar return-name="我的" current-name="优惠券"></nav-bar>
    <div class="app-nav-content">
      <van-tabs type="card">
        <van-tab :title="`未使用(${arrThree.length})`">
          <div class="item" v-for="(value, index) in arrThree" :key="index">
            <div class="wrap">
              <div>
                <div>
                  <p>￥{{value.preferential}}</p>
                  <p>{{value.title}}</p>
                </div>
                <div>
                  <p>满{{value.issuance_amount}}元可用</p>
                  <p>{{value.time}}到期</p>
                </div>
              </div>
              <div @click="goPage">去使用</div>
            </div>
            <p>{{value.used_rule}}</p>
          </div>
        </van-tab>
        <van-tab :title="`已使用(${arrOne.length})`">
          <div class="item disabled" v-for="(value, index) in arrOne" :key="index">
            <div class="wrap">
              <div>
                <div>
                  <p>￥{{value.preferential}}</p>
                  <p>{{value.title}}</p>
                </div>
                <div>
                  <p>满{{value.issuance_amount}}元可用</p>
                  <p>{{value.time}}到期</p>
                </div>
              </div>
              <div>已使用</div>
            </div>
            <p>{{value.used_rule}}</p>
          </div>
        </van-tab>
        <van-tab :title="`已过期(${arrTwo.length})`">
          <div class="item disabled" v-for="(value, index) in arrTwo" :key="index">
            <div class="wrap">
              <div>
                <div>
                  <p>￥{{value.preferential}}</p>
                  <p>{{value.title}}</p>
                </div>
                <div>
                  <p>满{{value.issuance_amount}}元可用</p>
                  <p>{{value.time}}到期</p>
                </div>
              </div>
              <div>已过期</div>
            </div>
            <p>{{value.used_rule}}</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import NavBar from '@/components/NavBar.vue';

  export default {
    name: 'Coupon',
    data() {
      return {
        arrOne: [],
        arrTwo: [],
        arrThree: []
      }
    },
    mounted () {
      this.$toast.loading({
        message: '加载中...',
        duration: 0
      });
      CommonService.userCoupons().then(res => {
        let arrOne = [];
        let arrTwo = [];
        let arrThree = [];
        res.coupon_list.forEach(val => {
          let has_use = val.has_use * 1;
          if (has_use === 1) {
            arrOne.push(val);
          } else if (has_use === 2) {
            arrTwo.push(val);
          } else {
            arrThree.push(val);
          }
        });
        this.arrOne = arrOne;
        this.arrTwo = arrTwo;
        this.arrThree = arrThree;
      }).catch(err => {}).finally(() => {
        this.$toast.clear();
      });
    },
    components: {
      NavBar,
    },
    methods: {
      goPage() {
        this.$router.push({
          path: '/category'
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .coupon {
    .iosBottom;
    /deep/ .van-tabs {
      padding: 0 44 * @r;
      margin-top: 24 * @r;
      .van-tabs__wrap {
        height: initial;
        .van-tabs__nav--card {
          border-radius: 0;
          margin: 0;
          border: 0;
          height: 46 * @r;
          margin-bottom: 16 * @r;
          .van-tab {
            border: 0;
            color: #6A6A6A;
            width: 140 * @r;
            flex: initial;
            font-size: 24 * @r;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8 * @r;
            & + .van-tab {
              margin-left: 30 * @r;
            }
            &.van-tab--active {
              background-color: @color-primary;
              color: #FFFFFF;
            }
          }
        }
      }
      .van-tabs__content {
        .van-tab__pane {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .item {
            .setImg('/coupon-bac.png');
            &.disabled {
              .wrap {
                & > div {
                  &:nth-of-type(2) {
                    border: none;
                    color: #979797;
                  }
                }
              }
            }
            .wrap {
              margin: 36 * @r 56 * @r 0 56 * @r;
              display: flex;
              align-items: center;
              justify-content: space-between;
              & > div {
                &:nth-of-type(1) {
                  & > div {
                    display: flex;
                    align-items: center;
                    &:nth-of-type(1) {
                      p {
                        &:nth-of-type(1) {
                          font-size: 46 * @r;
                          color: @color-primary;
                          width: 150 * @r;
                          margin-right: 20 * @r;
                        }
                        &:nth-of-type(2) {
                          width: 310 * @r;
                          font-size: 30 * @r;
                          font-weight: bold;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        }
                      }
                    }
                    &:nth-of-type(2) {
                      p {
                        font-size: 22 * @r;
                        color: #979797;
                        &:nth-of-type(1) {
                          width: 150 * @r;
                          margin-right: 20 * @r;
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(2) {
                  height: 38 * @r;
                  width: 108 * @r;
                  border: 1px solid @color-primary;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 22 * @r;
                  color: @color-primary;
                  border-radius: 19 * @r;
                }
              }
            }
            & > p {
              font-size: 22 * @r;
              color: #979797;
              padding: 37 * @r 56 * @r 0 56 * @r;
            }
          }
        }
      }
    }
  }
</style>