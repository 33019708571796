<template>
  <div class="nav-bar">
    <p @click="returnFunc()"><van-icon name="arrow-left"></van-icon>返回{{returnName}}</p>
    <p>{{currentName}}</p>
    <div class="right-region">
      <van-icon name="search" @click="$store.state.showSearchLayer = true" v-if="search"></van-icon>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavBar',
    props: {
      returnFunc: {
        type: Function,
        default() {
          return this.$router.back();
        }
      },
      returnName: {
        type: String,
        default: ''
      },
      currentName: {
        type: String,
        default: ''
      },
      search: {
        type: Boolean,
        default: false
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .nav-bar {
    display: flex;
    align-items: center;
    height: @navBarHeight;
    padding: 0 32 * @r;
    border-bottom: 1px solid #EBEBEB;
    position: relative;
    z-index: 1;
    background-color: #FFFFFF;
    & > p {
      font-size: 26 * @r;
      &:nth-of-type(1) {
        display: flex;
        align-items: center;
        border-right: 1px solid #D5D5D5;
        padding-right: 18 * @r;
        margin-right: 18 * @r;
        color: #6A6A6A;
        .van-icon {
          margin-right: 10 * @r;
        }
      }
      &:nth-of-type(2) {
        font-weight: bold;
      }
    }
    & > .right-region {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .van-icon {
        font-size: 36 * @r;
      }
    }
  }
</style>