<template>
  <div class="product-list">
    <van-checkbox-group v-model="result" v-if="isCheck">
      <div class="item" v-for="value in datas" :key="value.goods_id">
        <van-checkbox :name="value"></van-checkbox>
        <van-image :src="value[props.url]"></van-image>
        <div class="right-region">
          <p v-html="value[props.name]"></p>
          <p>
            <span v-if="value[props.spec]">规格 {{value[props.spec]}}<i v-if="value[props.suggestPrice]">|</i></span>
            <!-- <span v-if="value[props.per]">起订 {{value[props.per]}}<i>|</i></span> -->
            <span v-if="value[props.suggestPrice]">建议零售 ￥{{value[props.suggestPrice]}}</span>
          </p>
          <div class="bottom">
            <div>
              <p>￥{{value[props.price]}}</p>
              <p>起订 {{value[props.per]}}</p>
            </div>
            <van-stepper :value="value[props.number]" async-change @plus="plus(value)" @minus="minus(value)" min="0" disable-input :long-press="false"></van-stepper>
          </div>
        </div>
      </div>
    </van-checkbox-group>
    <div class="item order-item" v-for="value in datas" :key="value.goods_id" v-else-if="isOrder">
      <van-image :src="value[props.url]"></van-image>
      <div class="name">
        <p v-html="value[props.name]"></p>
        <p v-if="value[props.allowUserCoupon]">可用券</p>
      </div>
      <p><span>￥{{value[props.price]}}</span> x {{value[props.number]}}</p>
    </div>
    <div class="item cart-item" v-for="value in datas" :key="value.goods_id" v-else-if="isCart">
      <van-image :src="value[props.url]"></van-image>
      <div class="name">
        <p v-html="value[props.name]"></p>
        <p v-if="value[props.allowUserCoupon]">可用券</p>
      </div>
      <p>￥{{value[props.price]}}</p>
      <van-stepper :value="value[props.number]" async-change @plus="plus(value)" @minus="minus(value)" min="0" disable-input :long-press="false"></van-stepper>
    </div>
    <div class="item" v-for="value in datas" :key="value.goods_id" v-else>
      <van-image :src="value[props.url]"></van-image>
      <div class="right-region">
        <p v-html="value[props.name]"></p>
        <p>
          <span v-if="value[props.spec]">规格 {{value[props.spec]}}<i v-if="value[props.suggestPrice]">|</i></span>
          <!-- <span v-if="value[props.per]">起订 {{value[props.per]}}<i>|</i></span> -->
          <span v-if="value[props.suggestPrice]">建议零售 ￥{{value[props.suggestPrice]}}</span>
        </p>
        <p>
          <span v-if="value[props.allowUserCoupon]">可用券</span>
        </p>
        <div class="bottom">
          <div>
            <p>￥{{value[props.price]}}</p>
            <p><i v-if="value[props.oldPrice]">￥{{value[props.oldPrice]}}</i></p>
            <p v-if="value[props.per] && !value[props.number]">起订 {{value[props.per]}}</p>
          </div>
          <van-stepper :value="value[props.number]" async-change @plus="plus(value)" @minus="minus(value)" min="0" disable-input :long-press="false"></van-stepper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  
  export default {
    name: 'ProductList',
    props: {
      isCheck: {
        type: Boolean,
        default: false
      },
      isOrder: {
        type: Boolean,
        default: false
      },
      isCart: {
        type: Boolean,
        default: false
      },
      datas: {
        type: Array,
        default() {
          return [];
        }
      },
      props: {
        type: Object,
        default() {
          return {
            url: 'image',
            name: 'title',
            spec: 'spec',
            per: 'name_ext',
            price: 'price',
            max: 'max',
            number: 'number',
            suggestPrice: 'suggested_retail_price',
            oldPrice: 'old_price',
            allowUserCoupon: 'allow_user_coupon'
          }
        }
      }
    },
    data() {
      return {
        status: false,
        result: []
      }
    },
    methods: {
      plus(data) {
        if (this.status) {
          this.$notify({
            message: '拼命处理中，请稍后~',
            duration: 1000
          });
        } else {
          this.status = true;
          let merchantId = data.merchantId;
          CommonService.addCart(merchantId, data.id).then(res => {
            this.updateCartInfo(merchantId, () => {
              data.number += res.num * 1;
              // this.$store.commit('setCartTotalPrice', res.data.total_price);
              this.$emit('addCart');
            });
          }).catch(err => {
            
          }).finally(() => {
            this.status = false;
          });
        }
      },
      minus(data) {
        if (this.status) {
          this.$notify({
            message: '拼命处理中，请稍后~',
            duration: 1000
          });
        } else {
          this.status = true;
          let merchantId = data.merchantId;
          CommonService.removeCart(merchantId, data.id).then(res => {
            this.updateCartInfo(merchantId, () => {
              data.number -= res.num * 1;
              // this.$store.commit('setCartTotalPrice', res.data.total_price);
              this.$emit('removeCart');
            });
          }).catch(err => {
            
          }).finally(() => {
            this.status = false;
          });
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .product-list {
    .item {
      display: flex;
      align-items: center;
      &.order-item {
        justify-content: space-between;
        .van-image {
          width: 100 * @r;
          height: 100 * @r;
        }
        & > p {
          margin-right: 30 * @r;
          font-size: 24 * @r;
          &:nth-of-type(1) {
            width: 15%;
            color: #AFB2B9;
            text-align: center;
            flex: 1;
            text-align: right;
            span {
              color: #F56332;
            }
          }
        }
        .name {
          flex: 1;
          margin-right: 30 * @r;
          font-size: 24 * @r;
          p {
            &:nth-of-type(2) {
              font-size: 20 * @r;
              opacity: .4;
            }
          }
        }
      }
      &.cart-item {
        justify-content: space-between;
        .van-image {
          width: 100 * @r;
          height: 100 * @r;
        }
        & > p {
          margin-right: 30 * @r;
          font-size: 24 * @r;
          &:nth-of-type(1) {
            width: 15%;
            color: #AFB2B9;
            text-align: center;
          }
        }
        .name {
          flex: 1;
          margin-right: 30 * @r;
          font-size: 24 * @r;
          p {
            &:nth-of-type(2) {
              font-size: 20 * @r;
              opacity: .4;
            }
          }
        } 
        .van-stepper {
          flex-shrink: 0;
        }
      }
      & + .item {
        margin-top: 16 * @r;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid #EBEBEB;
        padding-bottom: 16 * @r;
      }
      .van-checkbox {
        margin-right: 38 * @r;
      }
      .van-image {
        width: 160 * @r;
        height: 160 * @r;
        margin-right: 30 * @r;
      }
      .right-region {
        flex: 1;
        & > p {
          display: flex;
          span {
            display: flex;
          }
          &:nth-of-type(1) {
            font-size: 26 * @r;
            margin-bottom: 20 * @r;
          }
          &:nth-of-type(2),
          &:nth-of-type(3) {
            font-size: 22 * @r;
            color: #AFB2B9;
            margin-bottom: 10 * @r;
          }
          i {
            color: #F56332;
            margin: 0 8 * @r;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > div {
            display: flex;
            align-items: center;
            & > p {
              &:nth-of-type(1) {
                font-size: 28 * @r;
                color: @color-primary;
                margin-right: 10 * @r;
              }
              &:nth-of-type(2) {
                font-size: 22 * @r;
                opacity: .4;
                text-decoration: line-through;
                margin-right: 20 * @r;
              }
              &:nth-of-type(3) {
                font-size: 22 * @r;
                opacity: .4;
              }
            }
          }
        }
      }
    }
  }
</style>