import Vue from 'vue';
import Vuex from 'vuex';
import * as math from 'mathjs';

Vue.use(Vuex);

let divideNumber = (numberOne, numberTwo) => {
  return math.divide(math.bignumber(numberOne), math.bignumber(numberTwo)) * 1;
};

export default new Vuex.Store({
  state: {
    cartTotalPrice: 0,
    reqParams: null,
    showSearchLayer: false,
    allInfo: null,
    addExtendOrderId: null
  },
  mutations: {
    setShowSearchLayer(state, params) {
      state.showSearchLayer = params;
    },
    setAddExtendOrderId(state, params) {
      state.addExtendOrderId = params;
    },
    setReqParams(state, params) {
      state.reqParams = params;
    },
    setCartTotalPrice(state, params) {
      state.cartTotalPrice = divideNumber(params, 100);
    },
    setAllInfo(state, params) {
      state.allInfo = params;
    }
  },
  actions: {
  },
  modules: {
  }
});
