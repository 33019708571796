<template>
  <div class="category-list">
    <nav-bar return-name="分类" :current-name="name" search :returnFunc="returnFunc"></nav-bar>
    <van-tabs v-model="tabActive" @change="initPage">
      <van-tab :title="value.name" v-for="(value, index) in datas" :key="index" :ref="`tab-content-${index}`" :class="{'no-side': !value.child.length}">
        <div class="side" v-if="value.child.length">
          <p v-for="(m, n) in value.child" :key="n" :class="{active: n === value.sideActive}" @click="scrollToTarget(n)">{{m.name}}</p>
        </div>
        <div class="list">
          <product-list :datas="m" v-for="(m, n) in value.datas" :key="n"></product-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import NavBar from '@/components/NavBar.vue';
  import ProductList from '@/components/ProductList.vue';

  export default {
    name: 'CategoryList',
    data() {
      return {
        returnFunc: () => {
          this.$router.push({
            path: '/category'
          });
        },
        name: null,
        tabActive: 0,
        sidePositionArr: [],
        listPositionArr: [],
        datas: []
      }
    },
    components: {
      NavBar,
      ProductList,
    },
    mounted() {
      this.initDatas().then(() => {
        this.initPage();
      });
    },
    destroyed() {
      document.querySelectorAll('.list').forEach(val => {
        val.removeEventListener('scroll', this.listScroll);
      });
    },
    methods: {
      getActiveDom() {
        let dom = this.$refs[`tab-content-${this.tabActive}`][0].$el;
        return {
          side: dom.querySelectorAll('.side')[0],
          list: dom.querySelectorAll('.list')[0]
        }
      },
      initPage() {
        this.$nextTick(() => {
          this.sideHeightArr();
          this.listHeightArr();
          this.getActiveDom().list.addEventListener('scroll', this.listScroll);
        });
      },
      initDatas() {
        this.$toast.loading({
          message: '加载中...',
          duration: 0
        });
        return CommonService.allInfo().then(res => {
          let id = this.$route.params.id;
          let target;
          let datas;
          res.cate.forEach(val => {
            if (val.father.id === id) {
              target = val;
              this.name = val.father.name;
            }
          });
          if (!target.child) {
            datas = target.sub_father.map(val => {
              val.sideActive = 0;
              // 三级分类
              res.cate.forEach(m => {
                if (m.father.id === val.id) {
                  val.child = m.child;
                }
              });
              // 商品
              val.datas = [];
              val.child.forEach(m => {
                val.datas.push(this.goodsDatas(m.id, res));
              });
              return val;
            });
          } else if (!target.sub_father && !(target.father.father_id * 1)) {
            datas = target.child.map(val => {
              // 三级分类
              val.child = [];
              // 商品
              val.datas = [];
              val.datas.push(this.goodsDatas(val.id, res));
              return val;
            });
          }
          this.datas = datas;
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      goodsDatas(id, res) {
        let arr = [];
        res.goods.forEach(val => {
          if (val.c_cate === id) {
            val.merchantId = res.merchant.id;
            val.image = `${process.env.VUE_APP_IMAGE_URL}${val.image.replace(process.env.VUE_APP_IMAGE_URL, '')}`;
            arr.push(val);
          }
        });
        return arr.map(val => {
          val.number = 0;
          res.cart.cart.forEach(m => {
            if (m.goods_id === val.id) {
              val.number = m.num * 1;
            }
          });
          return val;
        });
      },
      scrollToTarget(index) {
        this.datas[this.tabActive].sideActive = index;
        this.getActiveDom().list.scrollTop = this.listPositionArr[index];
      },
      listScroll() {
        let y = this.getActiveDom().list.scrollTop;
        this.listPositionArr.forEach((val, index) => {
          if (y >= val) {
            this.datas[this.tabActive].sideActive = index;
            this.getActiveDom().side.scrollTop = this.sidePositionArr[index];
          }
        });
      },
      sideHeightArr() {
        let arr = [];
        this.getActiveDom().side.childNodes.forEach(val => {
          arr.push(val.offsetTop - this.getActiveDom().side.offsetTop);
        });
        this.sidePositionArr = arr;
      },
      listHeightArr() {
        let arr = [];
        this.getActiveDom().list.childNodes.forEach(val => {
          arr.push(val.offsetTop - this.getActiveDom().list.offsetTop);
        });
        this.listPositionArr = arr;
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .category-list {
    height: 100%;
    .iosBottom;
    /deep/ .van-tabs {
      height: calc(100% - 87 * @r);
      .van-tabs__wrap {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
        &::after {
          border: none;
        }
        .van-tabs__nav {
          .van-tabs__line {
            background-color: @color-primary;
          }
        }
      }
      .van-tabs__content {
        height: calc(100% - 88 * @r);
        .van-tab__pane {
          height: 100%;
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 30 * @r 0;
          &.no-side {
            .list {
              padding: 0 30 * @r;
            }
          }
          .side {
            width: 140 * @r;
            background-color: #F3F4F7;
            overflow: auto;
            border-radius: 10 * @r;
            margin: 0 30 * @r;
            p {
              font-size: 24 * @r;
              color: #6A6A6A;
              height: 80 * @r;
              display: flex;
              align-items: center;
              justify-content: center;
              &.active {
                background-color: @color-primary;
                color: #FFFFFF;
              }
            }
          }
          .list {
            overflow: auto;
            flex: 1;
            padding-right: 30 * @r;
            .product-list {
              & + .product-list {
                margin-top: 16 * @r;
              }
              &:not(:last-of-type) {
                border-bottom: 1px solid #EBEBEB;
                padding-bottom: 16 * @r;
              }
            }
          }
        }
      }
    }
  }
</style>