<template>
  <div class="category">
    <div class="panel-1">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(value, index) in slideDatas" :key="index">
          <van-image :src="value"></van-image>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="panel-2">
      <div v-for="(value, index) in datas" :key="index" @click="handleItem(value.father.id)">
        <van-image :src="value.father.image"></van-image>
        <p>{{value.father.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';

  export default {
    name: 'Category',
    data() {
      return {
        slideDatas: [],
        datas: []
      }
    },
    mounted () {
      this.$toast.loading({
        message: '加载中...',
        duration: 0
      });
      CommonService.allInfo().then(res => {
        this.slideDatas = res.carousel.map(val => `${process.env.VUE_APP_IMAGE_URL}${val}`);
        this.datas = res.cate.filter(val => !val.child || (!val.sub_father && !(val.father.father_id * 1))).map(val => {
          val.father.image = `${process.env.VUE_APP_IMAGE_URL}${val.father.image}`;
          return val;
        });
      }).catch(err => {}).finally(() => {
        this.$toast.clear();
      });
    },
    methods: {
      handleItem(id) {
        this.$router.push({
          path: `/category_list/${id}`
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .category {
    .panel-1 {
      margin-top: 18 * @r;
      padding: 0 30 * @r;
    }
    .panel-2 {
      padding: 20 * @r 0;
      overflow: hidden;
      & > div {
        width: 126 * @r;
        margin: 15 * @r 62 * @r;
        float: left;
        .van-image {
          height: 126 * @r;
          margin-bottom: 8 * @r;
        }
        p {
          font-size: 26 * @r;
          text-align: center;
        }
      }
    }
  }
  /deep/ .van-swipe {
    height: 210 * @r;
    .van-swipe__track {
      .van-swipe-item {
        .van-image {
          height: 100%;
          border-radius: 16 * @r;
          margin: 0 10 * @r;
          img {
            border-radius: 16 * @r;
          }
        }
      }
    }
    .van-swipe__indicators {
      left: 36 * @r;
    }
  }
</style>