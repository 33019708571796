import CommonService from '@/io/common.js';
import { Toast } from 'vant';
import * as math from 'mathjs';
import Store from '@/store';

// 防抖
let debounce = (fn = () => { }, delay = 300) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
      timer = null;
    }, delay);
  };
}

// 节流
let throttle = (fn = () => { }, delay = 300) => {
  let timer;
  return (...args) => {
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      fn(...args);
      timer = null;
    }, delay);
  };
}

let scanCode = self => {
  wx.scanQRCode({
    needResult: 1,
    scanType: ["qrCode", "barCode"],
    success(res) {
      console.log(self)
      let result = res.resultStr;
      if (res.errMsg === 'scanQRCode:ok') {
        Toast.loading({
          message: '搜索中...',
          duration: 0
        });
        CommonService.allInfo().then(res => {
          return CommonService.search(null, result.split(',')[1], res.merchant.id);
        }).then(res => {
          setTimeout(() => {
            if (!res.goods_list || !res.goods_list.length) {
              Toast({
                message: '暂无该商品',
                duration: 1500
              });
            } else {
              self.$router.push({
                path: `/goods?ids=${res.goods_list.map(val => val.id).join(',')}`
              });
            }
          });
        }).catch(err => { }).finally(() => {
          Toast.clear();
        });
      }
    }
  });
}

let formatTime = time => time >= 0 && time <= 9 ? `0${time}` : time;

let currentDate = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = formatTime(date.getMonth() + 1);
  let day = formatTime(date.getDate());
  let hour = formatTime(date.getHours());
  let minute = formatTime(date.getMinutes());
  let seconds = formatTime(date.getSeconds());  
  return {
    year,
    month,
    day,
    hour,
    minute,
    seconds
  }
}

let updateCartInfo = (merchantId, callback = () => {}) => {
  CommonService.cartInfo(merchantId, Store.state.addExtendOrderId).then(res => {
    let allInfo = JSON.parse(JSON.stringify(Store.state.allInfo));
    allInfo.cart = res;
    Store.commit('setAllInfo', allInfo);
    Store.commit('setCartTotalPrice', res.total_price);
    callback();
  }).catch(err => { });
}

let addNumber = (numberOne, numberTwo) => {
  return math.add(math.bignumber(numberOne), math.bignumber(numberTwo)) * 1;
}

let subtractNumber = (numberOne, numberTwo) => {
  return math.subtract(math.bignumber(numberOne), math.bignumber(numberTwo)) * 1;
}

let multiplyNumber = (numberOne, numberTwo) => {
  return math.multiply(math.bignumber(numberOne), math.bignumber(numberTwo)) * 1;
}

let divideNumber = (numberOne, numberTwo) => {
  return math.divide(math.bignumber(numberOne), math.bignumber(numberTwo)) * 1;
}

let countDown = time => {
  
}

let install = (Vue, options = {}) => {
  Vue.prototype.debounce = debounce;
  Vue.prototype.throttle = throttle;
  Vue.prototype.currentDate = currentDate;
  Vue.prototype.addNumber = addNumber;
  Vue.prototype.subtractNumber = subtractNumber;
  Vue.prototype.multiplyNumber = multiplyNumber;
  Vue.prototype.divideNumber = divideNumber;
  Vue.prototype.updateCartInfo = updateCartInfo;
  Vue.prototype.formatTime = formatTime;
  Vue.mixin({
    methods: {
      scanCode() {
        scanCode(this);
      }
    },
  });
};

export default {
  install
};