import axios from 'axios';
import { Notify } from 'vant';
import {
  login
} from '@/io/login.js';

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  res => {
    if (res.data.status === 1 || res.data.appId) {
      return res.data;
    } else if (res.data.code === 400001) {
      login();
    } else {
      Notify({
        type: 'danger',
        message: res.data.err_msg,
        duration: res.data.code === 999 ? 0 : 1000
      });
      return Promise.reject(res);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);