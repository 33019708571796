<template>
  <div class="search">
    <div class="top">
      <van-icon name="scan" @click="scanCode"></van-icon>
      <div class="input-wrap">
        <van-field v-model="value" placeholder="搜索商品" @input="goSearch"></van-field>
        <van-icon name="search" @click="search"></van-icon>
      </div>
      <p @click="cancel">返回</p>
    </div>
    <div class="scroll">
      <template v-if="!value">
        <div class="list" v-if="historyDatas.length">
          <p>历史搜索</p>
          <div class="wrap">
            <p v-for="(value, index) in historyDatas" :key="index" @click="handleSearch(value)">{{value}}</p>
          </div>
        </div>
        <div class="list" v-if="hotDatas.length">
          <p>热门搜索</p>
          <div class="wrap">
            <p v-for="(value, index) in hotDatas" :key="index" @click="handleSearch(value)">{{value}}</p>
          </div>
        </div>
      </template>
      <product-list :datas="datas"></product-list>
      <div class="app-bottom" v-if="value">{{datas.length ? '您已滑到最底部~' : '暂无数据'}}</div>
    </div>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import ProductList from '@/components/ProductList.vue';

  export default {
    name: 'Search',
    data() {
      return {
        value: '',
        datas: [],
        historyDatas: [],
        hotDatas: [],
        goSearch: this.debounce(this.search, 500)
      }
    },
    watch: {
      value: {
        handler(newValue, oldValue) {
          if (!newValue) {
            this.datas = [];
            this.initDatas();
          }
        },
        immediate: true
      }
    },
    components: {
      ProductList,
    },
    methods: {
      cancel() {
        this.value = '';
        this.datas = [];
        this.$store.state.showSearchLayer = false;
      },
      handleSearch(keywords) {
        this.value = keywords;
        this.search();
      },
      initDatas() {
        this.$toast.loading({
          message: '加载中...',
          duration: 0
        });
        CommonService.searchHistoryList().then(res => {
          this.historyDatas = res.data.user_search_history;
          this.hotDatas = res.data.hot_search;
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      search() {
        if (this.value) {
          let merchantId;
          let cart;
          this.$toast.loading({
            message: '加载中...',
            duration: 0
          });
          CommonService.allInfo().then(res => {
            merchantId = res.merchant.id;
            cart = res.cart.cart;
            return CommonService.search(this.value, null, res.merchant.id);
          }).then(response => {
            CommonService.addSearchHistory(this.value);
            this.datas = response.goods_list.map(val => {
              val.merchantId = merchantId;
              val.image = `${process.env.VUE_APP_IMAGE_URL}${val.image}`;
              val.number = 0;
              cart.forEach(m => {
                if (m.goods_id === val.id) {
                  val.number = m.num * 1;
                }
              });
              return val;
            });
          }).catch(err => {}).finally(() => {
            this.$toast.clear();
          });
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .search {
    height: 100%;
    overflow: hidden;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32 * @r;
      margin-bottom: 40 * @r;
      & > .van-icon {
        font-size: 40 * @r;
        color: #696969;
        margin-left: 34 * @r;
      }
      .input-wrap {
        display: flex;
        align-items: center;
        background-color: #F3F4F7;
        flex: 1;
        padding: 0 30 * @r;
        border-radius: 28 * @r;
        margin: 0 24 * @r 0 34 * @r;
        /deep/ .van-cell {
          background-color: transparent;
          padding: 8 * @r 0;
          &::after {
            display: none;
          }
          .van-cell__value {
            .van-field__body {
              input {
                text-align: center;
                font-size: 24 * @r;
              }
            }
          }
        }
        .van-icon {
          font-size: 36 * @r;
          color: #959AA3;
        }
      }
      & > p {
        font-size: 26 * @r;
        color: #82858B;
        margin-right: 30 * @r;
      }
    }
    .list {
      padding: 0 40 * @r;
      margin-bottom: 60 * @r;
      & > p {
        font-size: 26 * @r;
        color: #82858B;
        margin-bottom: 22 * @r;
      }
      .wrap {
        display: flex;
        flex-wrap: wrap;
        margin: -13 * @r -10 * @r; 
        p {
          height: 56 * @r;
          display: flex;
          align-items: center;
          font-size: 26 * @r;
          color: #82858B;
          background-color: #F3F4F7;
          padding: 0 24 * @r;
          border-radius: 28 * @r;
          margin: 13 * @r 10 * @r;
        }
      }
    }
    .product-list {
      padding: 0 40 * @r;
    }
    .scroll {
      height: calc(100% - 136 * @r);
      overflow: auto;
    }
  }
</style>