import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';

import WxBlank from '@/views/WxBlank.vue';

import Main from '@/views/Main.vue';
import Home from '@/views/Home.vue';
import Category from '@/views/Category.vue';
import Cart from '@/views/Cart.vue';
import User from '@/views/User.vue';
import CategoryList from '@/views/CategoryList.vue';
import Coupon from '@/views/Coupon.vue';
import Order from '@/views/Order.vue';
import Address from '@/views/Address.vue';
import Service from '@/views/Service.vue';
import Pay from '@/views/Pay.vue';
import Goods from '@/views/Goods.vue';

import {
  login
} from '@/io/login.js';

Vue.use(VueRouter);

let beforeEnter = (to, from, next) => {
  if (Store.state.reqParams) {
    if (to.query.add_extend_order_id) {
      Store.commit('setAddExtendOrderId', to.query.add_extend_order_id);
    }
    next();
  } else {
    login();
  }
}

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
        beforeEnter
      },
      {
        path: 'category',
        name: 'Category',
        component: Category,
        beforeEnter
      },
      {
        path: '/category_list/:id',
        name: 'CategoryList',
        component: CategoryList,
        beforeEnter
      },
      {
        path: 'cart',
        name: 'Cart',
        component: Cart,
        beforeEnter
      },
      {
        path: 'user',
        name: 'User',
        component: User,
        beforeEnter
      },
      {
        path: 'goods',
        name: 'Goods',
        component: Goods,
        beforeEnter
      },
    ]
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: Coupon,
    beforeEnter
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
    beforeEnter
  },
  {
    path: '/address',
    name: 'Address',
    component: Address,
    beforeEnter
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
    beforeEnter
  },
  {
    path: '/pay',
    name: 'Pay',
    component: Pay,
    beforeEnter
  },
  {
    path: '/wx_blank',
    name: 'WxBlank',
    component: WxBlank
  }
];

const router = new VueRouter({
  routes
});

export default router;
