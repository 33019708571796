<template>
  <div class="order app-nav-wrap">
    <nav-bar :returnFunc="returnFunc" return-name="我的" current-name="我的订单"></nav-bar>
    <div class="app-nav-content">
      <div class="item" v-for="(value, index) in datas" :key="index">
        <p><i>订单号1：</i>{{value.orderid}}</p>
        <p><i>下单时间：</i>{{value.datetime}}</p>
        <p><i>送货地址：</i>{{value.user_distribution && value.user_distribution.address}}<a v-if="value.allow_update_user_distribution" @click="showLayer(value)">修改</a></p>
        <p><span><i>订单金额：</i>￥{{addNumber(value.total_fee, value.coupon ? divideNumber(value.coupon.preferential, 100) : 0)}}</span><span v-if="value.coupon"><i>优惠券：</i>￥{{divideNumber(value.coupon.preferential, 100)}}</span></p>
        <p class="active"><i>支付金额：</i>￥{{value.total_fee}}</p>
        <div class="btn large" v-if="value.allow_add_order" @click="addExtendOrder(value)">加货</div>
        <div class="btn large" v-if="!value.showInfo" @click="showInfo(value)">展示全部订单</div>
        <template v-else>
          <product-list :datas="value.datas" is-order></product-list>
          <div class="btn" @click="value.showInfo = false">收起</div>
        </template>
      </div>
    </div>
    <van-popup v-model="layer.show" position="bottom" safe-area-inset-bottom>
      <address-list is-layer @cancel="layer.show = false" @select="changeAddress"></address-list>
    </van-popup>
  </div>
</template>

<script>
  import NavBar from '@/components/NavBar.vue';
  import ProductList from '@/components/ProductList.vue';
  import AddressList from '@/views/Address.vue';
  import CommonService from '@/io/common.js';

  export default {
    name: 'Order',
    data() {
      return {
        returnFunc: () => {
          this.$router.push({
            path: '/user'
          });
        },
        merchantId: null,
        datas: [],
        layer: {
          id: null,
          show: false
        }
      }
    },
    components: {
      NavBar,
      ProductList,
      AddressList
    },
    mounted() {
      this.$store.commit('setAddExtendOrderId', null);
      this.$toast.loading({
        message: '加载中...',
        duration: 0
      });
      CommonService.allInfo().then(res => {
        this.merchantId = res.merchant.id;
        return this.initOrderList();
      }).then(res => {
        this.updateCartInfo(this.merchantId);
      }).catch(err => {

      }).finally(() => {
        this.$toast.clear();
      });
    },
    methods: {
      initOrderList() {
        return CommonService.orderList(this.merchantId).then(res => {
          this.datas = res.order_list_data.map(val => {
            val.datas = [];
            val.showInfo = false;
            return val;
          });
        });
      },
      showLayer(data) {
        this.layer.id = data.orderid;
        this.layer.show = true;
      },
      changeAddress(data) {
        this.$toast.loading({
          message: '处理中...',
          duration: 0
        });
        CommonService.updateOrderAddress(this.layer.id, data.id).then(res => {
          return this.initOrderList();
        }).catch(err => {}).finally(() => {
          this.layer.show = false;
          this.$toast.clear();
        });
      },
      addExtendOrder(data) {
        this.$store.commit('setAddExtendOrderId', data.orderid);
        this.$toast.loading({
          message: '处理中...',
          duration: 0
        });
        this.updateCartInfo(this.merchantId, () => {
          this.$toast.clear();
          this.$router.push('/');
        });
      },
      showInfo(data) {
        if (data.datas.length) {
          data.showInfo = true;
        } else {
          this.$toast.loading({
            message: '处理中...',
            duration: 0
          });
          CommonService.orderListInfo(data.orderid, this.merchantId).then(res => {
            data.datas = res.goods_list.map(val => {
              val.title = val.goods_name;
              val.number = val.num;
              val.image = `${process.env.VUE_APP_IMAGE_URL}${val.thumb}`;
              return val;
            });
            data.showInfo = true;
          }).catch(err => {}).finally(() => {
            this.$toast.clear();
          });
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .order {
    background-color: #F6F7F9;
    .iosBottom;
    .nav-bar {
      background-color: initial;
    }
    .item {
      background-color: #FFFFFF;
      margin: 28 * @r 44 * @r;
      padding: 34 * @r 44 * @r;
      border-radius: 16 * @r;
      & > p {
        font-size: 26 * @r;
        display: flex;
        &.active,
        a {
          color: #F56332;
        }
        & + p {
          margin-top: 20 * @r;
        }
        i {
          color: #6A6A6A;
        }
        span {
          flex: 1;
        }
        a {
          margin-left: 10 * @r;
          text-decoration: underline;
        }
      }
      .product-list {
        margin-top: 28 * @r;
        border-top: 1px solid #ADADAD;
        padding-top: 28 * @r;
      }
      .btn {
        border: 1px solid #979797;
        height: 46 * @r;
        padding: 0 44 * @r;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20 * @r;
        border-radius: 21 * @r;
        margin: 28 * @r auto 0 auto;
        width: 140 * @r;
        &.large {
          width: 240 * @r;
        }
      }
    }
  }
  .van-popup {
    height: 100%;
  }
</style>