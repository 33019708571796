<template>
  <div class="cart">
    <div class="content">
      <div class="panel-2-wrap" v-if="datas.length">
        <div class="panel-2">
          <div class="app-title">
            <span>为您推荐</span>
            <span @click="goGoods">更多<van-icon name="arrow"></van-icon></span>
          </div>
          <product-list :datas="datas.slice(0, 2)" @addCart="initPage" @removeCart="initPage"></product-list>
        </div>
      </div>
      <div class="panel-1-wrap" v-if="cartDatas.length">
        <div class="cart-top">
          <p>购物车<span>共{{cartDatas.length}}件商品</span></p>
          <p @click="emptyCart"><span>清空购物车</span></p>
        </div>
        <div class="empty" v-if="!cartDatas.length"></div>
        <div class="panel-1" v-else>
          <product-list :datas="cartDatas" @addCart="initPage" @removeCart="initPage" is-cart></product-list>
        </div>
      </div>
    </div>
    <div class="bottom" v-if="$store.state.cartTotalPrice < limitPrice">
      <div class="left-region">
        <p>
          <span>合计：<i>￥{{$store.state.cartTotalPrice}}</i></span>
          <span v-if="$store.state.allInfo && $store.state.allInfo.cart.allow_coupon_total_price">可用券金额：￥{{divideNumber($store.state.allInfo.cart.allow_coupon_total_price, 100)}}</span>
        </p>
        <p>还差<span>￥{{subtractNumber(limitPrice, $store.state.cartTotalPrice)}}</span>，满<span>{{limitPrice}}元</span>起送</p>
      </div>
      <van-button @click="goPage">去凑单</van-button>
    </div>
    <div class="bottom" v-else>
      <div class="left-region">
        <p>
          <span>合计：<i>￥{{$store.state.cartTotalPrice}}</i></span>
          <span v-if="$store.state.allInfo && $store.state.allInfo.cart.allow_coupon_total_price">可用券金额：￥{{divideNumber($store.state.allInfo.cart.allow_coupon_total_price, 100)}}</span>
        </p>
        <p>满{{limitPrice}}元起送</p>
      </div>
      <van-button @click="goOrder">下一步</van-button>
    </div>
    <van-dialog v-model="dialog.show" title="您当前订单" show-cancel-button confirm-button-text="去结算" cancel-button-text="去凑单" @confirm="dialogConfirm" @cancel="dialogCancel">
      <div class="dialog-text">
        <p v-if="dialog.textOne">{{dialog.textOne}}</p>
        <p v-if="dialog.textTwo">{{dialog.textTwo}}</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import ProductList from '@/components/ProductList.vue';

  export default {
    name: 'Cart',
    data() {
      return {
        dialog: {
          show: false,
          textOne: '',
          textTwo: ''
        },
        limitPrice: 0,
        cartDatas: [],
        merchantId: null,
        datas: []
      }
    },
    components: {
      ProductList
    },
    mounted () {
      this.initPage();
    },
    methods: {
      dialogConfirm() {
        this.$router.push({
          path: '/pay'
        });
      },
      dialogCancel() {
        this.$router.push({
          path: '/category'
        });
      },
      initPage() {
        this.$toast.loading({
          message: '加载中...',
          duration: 0
        });
        CommonService.allInfo().then(res => {
          this.merchantId = res.merchant.id;
          this.initCartDatas(this.$store.state.allInfo);
          this.initDatas(res);
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      goGoods() {
        this.$router.push({
          path: `/goods?ids=${this.datas.map(val => val.id).join(',')}`
        });
      },
      goPage() {
        this.$router.push({
          path: '/category'
        });
      },
      emptyCart() {
        this.$toast.loading({
          message: '处理中...',
          duration: 0
        });
        CommonService.emptyCart(this.merchantId).then(res => {
          setTimeout(() => {
            // this.$store.commit('setCartTotalPrice', 0);
            this.updateCartInfo(this.merchantId, this.initPage);
          });
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      initCartDatas(res) {
        this.limitPrice = this.divideNumber(res.cart.allow_submit_order_min_price, 100);
        this.cartDatas = res.cart.cart.map(val => {
          let data;
          res.goods.forEach(m => {
            if (val.goods_id === m.id) {
              data = {
                ...m,
                number: val.num * 1,
                merchantId: this.merchantId,
                image: `${process.env.VUE_APP_IMAGE_URL}${m.image}`,
              }
            }
          });
          return data;
        });
      },
      initDatas(res) {
        let goods = res.goods;
        let cartDatas = res.cart.cart;
        let datas = [];
        return CommonService.recommendGoods().then(res => {
          res.data.forEach(val => {
            goods.forEach(m => {
              if (val.goods_id === m.id) {
                datas.push({
                  ...m,
                  number: 0,
                  merchantId: this.merchantId,
                  image: `${process.env.VUE_APP_IMAGE_URL}${m.image}`,
                });
              }
            });
          });
          this.datas = datas.map(val => {
            let number = 0;
            cartDatas.forEach(m => {
              if (m.goods_id === val.id) {
                number = m.num * 1;
              }
            });
            val.number = number;
            return val;
          });
        }).catch(err => {});
      },
      goOrder() {
        this.$toast.loading({
          message: '处理中...',
          duration: 0
        });
        CommonService.allInfo().then(res => {
          let gift = res.cart.gift_info;
          if (gift.now) {
            this.dialog.textOne = `${gift.now.pre_text}共￥${this.$store.state.cartTotalPrice}，${gift.now.content}`;
          }
          if (gift.next) {
            this.dialog.textTwo = `${gift.next.pre_text}还差￥${this.subtractNumber(gift.next.min_price, this.$store.state.cartTotalPrice)}，${gift.next.content}`;
          }
          this.dialog.show = true;
        }).catch(err => {

        }).finally(() => {
          this.$toast.clear();
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .cart {
    position: relative;
    .dialog-text {
      text-align: center;
      padding: 10 * @r 20 * @r;
      p {
        margin: 20 * @r 0;
        opacity: .4;
      }
    }
    .content {
      height: calc(100% - 110 * @r);
      overflow: auto;
      padding: 30 * @r 40 * @r;
      .cart-top {
        // padding: 0 40 * @r;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 120 * @r;
        & > p {
          font-size: 34 * @r;
          font-weight: bold;
          // margin-bottom: 26 * @r;
          span {
            font-size: 22 * @r;
            color: #AFB2B9;
            margin-left: 18 * @r;
            font-weight: initial;
          }
        }
      }
      .panel-1-wrap {
        // padding: 0 40 * @r 28 * @r 40 * @r;
        // max-height: 60%;
        // overflow: auto;
      }
      .panel-2-wrap {
        // padding: 28 * @r 40 * @r;
        // max-height: 40%;
        // overflow: auto;
      }
      .empty {
        .setImg('/cart-empty.png');
        margin: 84 * @r auto 126 * @r auto;
      }
    }
    .bottom {
      height: 110 * @r;
      position: absolute;
      bottom: 0;
      border-top: 1px solid #D5D5D5;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .left-region {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 30 * @r;
        flex: 1;
        & > p {
          &:nth-of-type(1) {
            display: flex;
            align-items: center;
            line-height: 100%;
            span {
              &:nth-of-type(1) {
                font-size: 28 * @r;
              }
              &:nth-of-type(2) {
                margin-left: 10 * @r;
                font-size: 24 * @r;
              }
              i {
                color: @color-primary;
              }
            }
          }
          &:nth-of-type(2) {
            font-size: 22 * @r;
            color: #AFB2B9;
            span {
              color: @color-danger;
            }
          }
        }
      }
      .van-button {
        height: 100%;
        border: none;
        padding: 0;
        width: 206 * @r;
        background-color: @color-primary;
        color: #FFFFFF;
        border-radius: 0;
      }
    }
  }
</style>