<template>
  <div class="main">
    <router-view></router-view>
    <van-tabbar v-model="active" route safe-area-inset-bottom>
      <van-tabbar-item replace to="/home" icon="shop-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/category" icon="apps-o">分类</van-tabbar-item>
      <van-tabbar-item replace to="/cart" icon="shopping-cart-o" :badge="`￥${$store.state.cartTotalPrice}`">购物车</van-tabbar-item>
      <van-tabbar-item replace to="/user" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
  export default {
    name: 'Main',
    data() {
      return {
        active: 0
      }
    }
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .main {
    height: 100%;
    & > div:nth-of-type(1) {
      overflow: auto;
      .bottomMenuContentHeight;
    }
  }
</style>