<template>
  <div class="pay">
    <div class="content">
      <div class="top">
        <p>商品结算<span>共{{total}}件商品</span></p>
        <p @click="$router.push({ path: '/cart' })">返回</p>
      </div>
      <div class="address">
        <div v-if="address">
          <p>{{address.name}}<span>{{address.phone_num}}</span></p>
          <p>{{address.address}}</p>
        </div>
        <div v-else>
          <p>暂无地址</p>
        </div>
        <div @click="showLayer = true">更换地址<van-icon name="arrow"></van-icon></div>
      </div>
      <div class="mode">
        <div id="submit_1" v-if="isWxPay" :class="{active: selectMode === 1}" @click="selectMode = 1"><i></i><span>微信支付</span></div>
        <div id="submit_2" v-if="isCashPay" :class="{active: selectMode === 2}" @click="selectMode = 2"><i></i><span>货到付款</span></div>
        <div id="submit_3" v-if="!isMini" :class="{active: selectMode === 3}" @click="selectMode = 3"><i></i><span>支付宝</span></div>
      </div>
      <p class="pay-btns" v-if="isShowGoods"><span></span><span @click="switchShowGoods">收起</span></p>
      <p class="pay-btns" v-else><span></span><span @click="switchShowGoods">展开</span></p>
      <product-list :datas="datas" is-order v-if="datas.length && isShowGoods"></product-list>
      <product-list :datas="datas.slice(0, 1)" is-order v-if="datas.length && !isShowGoods"></product-list>
      <div class="coupon" v-if="couponDatas.length || couponNotAllowDatas.length">
        <p class="pay-btns"><span></span><span @click="goPage">去凑单</span></p>
        <div class="wrap" v-for="(value, index) in couponDatas" :key="index" @click="selectCouponItem(value)">
          <p class="active"><span>{{value.typeName}}</span>{{value.coupon.title}}<i>{{value.last_date_time}}</i></p>
          <van-checkbox v-model="value.active"></van-checkbox>
        </div>
        <div class="wrap" v-for="(value, index) in couponNotAllowDatas" :key="`not-allow-${index}`">
          <p><span>{{value.typeName}}</span>{{value.coupon.title}}<i>{{value.last_date_time}}</i></p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="left-region">
        <p>合计：<span>￥{{totalPrice - couponsPrice >= 0 ? subtractNumber(totalPrice, couponsPrice) : 0}}</span></p>
        <p>商品总额：￥{{totalPrice}} 优惠券：￥{{couponsPrice}}</p>
      </div>
      <van-button @click="goPay" id="submit_4">提交订单</van-button>
    </div>
    <van-popup v-model="showLayer" position="bottom" safe-area-inset-bottom>
      <address-list is-layer @cancel="showLayer = false" @select="changeAddress"></address-list>
    </van-popup>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import ProductList from '@/components/ProductList.vue';
  import AddressList from '@/views/Address.vue';

  export default {
    name: 'Pay',
    data() {
      return {
        isMini: false,
        isWxPay: true,
        isCashPay: true,
        selectMode: null, // 1微信支付，2.货到付款，3支付宝，待开发,
        merchantId: null,
        address: null,
        datas: [],
        showLayer: false,
        couponDatas: [],
        couponNotAllowDatas: [],
        selectCoupons: [],
        totalPrice: 0,
        couponsPrice: 0,
        isShowGoods: false
      }
    },
    components: {
      AddressList,
      ProductList
    },
    computed: {
      total() {
        let total = 0;
        this.datas.forEach(val => {
          total += val.number;
        });
        return total;
      }
    },
    mounted() {
      this.$toast.loading({
        message: '加载中...',
        duration: 0
      });
      wx.miniProgram.getEnv(res => {
        this.isMini = res.miniprogram;
        CommonService.allInfo().then(res => {
          this.merchantId = res.merchant.id;
          this.initDatas(res);
          this.initTotalPrice();
          Promise.all([
            this.initInfo(),
            this.initCoupons()
          ]).finally(() => {
            this.$toast.clear();
          });
        }).catch(err => {});
      });
    },
    methods: {
      switchShowGoods() {
        this.isShowGoods = !this.isShowGoods;
      },
      goPage() {
        this.$router.push({
          path: '/category'
        });
      },
      initAddress(addressId) {
        return CommonService.userInfo(addressId, this.merchantId, this.$store.state.addExtendOrderId).then(res => {
          this.address = res.userinfo;
          this.isWxPay = !!res.weixin_pay;
          this.isCashPay = !!res.cash_pay;
          this.selectMode = res.pay_cash_first + 1;
        }).catch(err => {

        });
      },
      initInfo() {
        return CommonService.orderAddress().then(res => {
          let id = res.first_user_info_id;
          if (res.userinfo_allow_count > 0 && id) {
            return this.initAddress(id);
          } else {
            this.showLayer = true;
          }
        }).catch(err => {

        });
      },
      initDatas(res) {
        this.datas = res.cart.cart.map(val => {
          let data;
          res.goods.forEach(m => {
            if (val.goods_id === m.id) {
              data = {
                ...m,
                number: val.num * 1,
                merchantId: val.merchantid,
                image: `${process.env.VUE_APP_IMAGE_URL}${m.image}`,
              }
            }
          });
          return data;
        });
      },
      initTotalPrice() {
        let totalPrice = 0;
        this.datas.forEach(val => {
          totalPrice = this.addNumber(this.multiplyNumber(val.number, val.price), totalPrice);
        });
        this.totalPrice = totalPrice;
      },
      initCoupons() {
        return CommonService.cartInfo(this.merchantId).then(res => {
          this.couponDatas = res.coupon_list.map(val => {
            return {
              active: false,
              ...this.formatCouponData(val)
            };
          });
          this.initCouponsPrice();
          this.couponNotAllowDatas = res.coupon_list_not_allow.map(val => {
            return this.formatCouponData(val);
          });
        }).catch(err => {});
      },
      initCouponsPrice() {
        let max = 0, maxIndex;
        this.couponDatas.forEach((val, index) => {
          if (val.type === '4') {
            this.selectCouponItem(val);
          } else {
            if (val.coupon.preferential * 1 > max) {
              max = val.coupon.preferential * 1;
              maxIndex = index;
            }
          }
        });
        if (typeof maxIndex === 'number') {
          this.selectCouponItem(this.couponDatas[maxIndex]);
        }
      },
      formatCouponData(data) {
        return {
          typeName: data.type === '4' ? '部分满减券' : '全场满减券',
          ...data
        };
      },
      changeAddress(data) {
        this.showLayer = false;
        this.initAddress(data.id);
      },
      selectCouponItem(data) {
        let totalPrice = this.couponsPrice;
        let status = this.isSelectedCoupon(data).status;
        let position = this.isSelectedCoupon(data).position;
        if (status) {
          totalPrice = this.subtractNumber(totalPrice, this.divideNumber(data.coupon.preferential, 100));
          this.selectCoupons.splice(position, 1);
        } else {
          let oldData = this.selectedNormalCoupon();
          if (data.type === '2' && oldData) {
            this.selectCouponItem(oldData);
            totalPrice = this.couponsPrice;
          }
          totalPrice = this.addNumber(totalPrice, this.divideNumber(data.coupon.preferential, 100));
          this.selectCoupons.push(data);
        }
        data.active = !data.active;
        this.couponsPrice = totalPrice;
      },
      selectedNormalCoupon() {
        let data;
        this.selectCoupons.forEach((val, index) => {
          if (val.type === '2') {
            data = val;
          }
        });
        return data;
      },
      isSelectedCoupon(data) {
        let status = false;
        let position;
        this.selectCoupons.forEach((val, index) => {
          if (val.id === data.id) {
            status = true;
            position = index;
          }
        });
        return {
          status,
          position
        };
      },
      createOrder(callback = () => {}) {
        this.$toast.loading({
          message: '订单提交中...',
          duration: 0
        });
        CommonService.createOrder(this.merchantId, this.address.id, this.selectMode, this.selectCoupons.map(val => val.id), this.$store.state.addExtendOrderId, this.isMini ? 1 : 0).then(res => {
          callback(res);
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      goPay() {
        if (!this.address) {
          this.$notify({
            type: 'danger',
            message: '请选择地址',
            duration: 1000
          });
        } else {
          if (this.selectMode === 1) {
            if (this.isMini) {
              this.createOrder(data => {
                wx.miniProgram.navigateTo({url: `/pages/pay/pay?timeStamp=${data.js_param.timeStamp}&nonceStr=${data.js_param.nonceStr}&package=${encodeURIComponent(data.js_param.package)}&signType=${data.js_param.signType}&paySign=${data.js_param.paySign}&redirect_uri_success=${encodeURIComponent(`${window.location.origin}/#/order`)}&redirect_uri_fail=${encodeURIComponent(`${window.location.origin}/#/pay`)}`});
              });
            } else {
              window.location.href = `/pay.html?merchantId=${this.merchantId}&addressId=${this.address.id}&openid=${this.$store.state.reqParams.openid}&token=${this.$store.state.reqParams.token}&couponsIds=${this.selectCoupons.map(val => val.id).join(',')}&add_extend_order_id=${this.$store.state.addExtendOrderId}`;
            }
          } else if (this.selectMode === 2) {
            this.createOrder(() => {
              this.$router.replace({
                path: '/order'
              });
            });
          } else if (this.selectMode === 3) {
            window.location.href = `${process.env.VUE_APP_DOMAIN}/Order/alipay.html?merchantid=${this.merchantId}&userinfo_id=${this.address.id}&openid=${this.$store.state.reqParams.openid}&token=${this.$store.state.reqParams.token}&user_coupon_ids=${this.selectCoupons.map(val => val.id).join(',')}&add_extend_order_id=${this.$store.state.addExtendOrderId}`;
          }
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .pay {
    background-color: #F6F7F9;
    .iosBottom;
    height: 100%;
    height: calc(100% - constant(safe-area-inset-bottom));
    height: calc(100% - env(safe-area-inset-bottom));
    position: relative;
    .pay-btns {
      display: flex;
      justify-content: space-between;
      font-size: 22 * @r;
      color: #838384;
      padding: 0 42 * @r;
      margin-bottom: 22 * @r;
    }
    .content {
      height: calc(100% - 110 * @r);
      overflow: auto;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 28 * @r 42 * @r;
        p {
          font-size: 34 * @r;
          span {
            font-size: 22 * @r;
            margin-left: 12 * @r;
            color: #838384;
          }
          &:last-of-type {
            color: #AFB2B9;
            font-size: 26 * @r;
            border: 1px solid #E4E4E4;
            width: 84 * @r;
            height: 44 * @r;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 22 * @r;
          }
        }
      }
      .address {
        display: flex;
        justify-content: space-between;
        padding: 28 * @r 32 * @r;
        background-color: #FFFFFF;
        border-radius: 10 * @r;
        margin: 0 42 * @r;
        & > div {
          &:nth-of-type(1) {
            flex: 1;
            p {
              & + p {
                margin-top: 6 * @r;
              }
              font-size: 26 * @r;
              span {
                font-size: 22 * @r;
                color: #9B9DA3;
                margin-left: 34 * @r;
              }
            }
          }
          &:nth-of-type(2) {
            display: flex;
            align-items: center;
            font-size: 22 * @r;
            color: #AFB2B9;
          }
        }
      }
      .mode {
        display: flex;
        margin: 32 * @r 42 * @r;
        & > div {
          width: 202 * @r;
          height: 147 * @r;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #FFFFFF;
          border-radius: 10 * @r;
          &.active {
            border: 1px solid #F56332;
          }
          & + div {
            margin-left: 32 * @r;
          }
          &:nth-of-type(1) {
            i {
              .setImg('/pay-icon-1.png');
            }
          }
          &:nth-of-type(2) {
            i {
              .setImg('/pay-icon-2.png');
            }
          }
          &:nth-of-type(3) {
            i {
              .setImg('/pay-icon-3.png');
            }
          }
          i {
            margin-bottom: 14 * @r;
          }
          span {
            font-size: 26 * @r;
          }
        }
      }
      .product-list {
        background-color: #FFFFFF;
        padding: 44 * @r 42 * @r;
        border-radius: 26 * @r;
      }
      .coupon {
        margin: 28 * @r 0;
        .wrap {
          background-color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24 * @r 42 * @r;
          &:not(:last-of-type) {
            border-bottom: 1px solid #EBEBEB;
          }
          & > p {
            font-size: 24 * @r;
            display: flex;
            align-items: center;
            &.active {
              span {
                background-color: @color-primary;
              }
            }
            span {
              width: 142 * @r;
              height: 45 * @r;
              background-color: #ACACAC;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 22 * @r;
              border-radius: 8 * @r;
              color: #FFFFFF;
            }
            i {
              opacity: .5;
              font-size: 20 * @r;
              margin-left: 22 * @r;
            }
          }
          .van-checkbox {
            flex-shrink: 0;
          }
        }
      }
    }
    .bottom {
      height: 110 * @r;
      position: absolute;
      bottom: 0;
      border-top: 1px solid #D5D5D5;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: #FFFFFF;
      .left-region {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 30 * @r;
        flex: 1;
        & > p {
          &:nth-of-type(1) {
            font-size: 28 * @r;
            span {
              color: @color-primary;
            }
          }
          &:nth-of-type(2) {
            font-size: 22 * @r;
            color: #AFB2B9;
            span {
              color: @color-danger;
            }
          }
        }
      }
      .van-button {
        height: 100%;
        border: none;
        padding: 0;
        width: 206 * @r;
        background-color: @color-primary;
        color: #FFFFFF;
        border-radius: 0;
      }
    }
  }
  .van-popup {
    height: 100%;
  }
</style>