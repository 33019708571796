<template>
  <div class="service app-nav-wrap">
    <nav-bar :returnFunc="returnFunc" return-name="我的" current-name="服务反馈">
      <template #right>
        <p @click="showLayer = true">上传服务反馈信息</p>
      </template>
    </nav-bar>
    <div class="app-nav-content">
      <div class="item" v-for="(value, index) in datas" :key="index">
        <p><i>生产日期：</i>{{value.manufacture_date}}</p>
        <p><i>地址：</i>{{value.address}}</p>
        <p><i>完成状态：</i>{{value.finish_status ? '已完成' : '未完成'}}</p>
        <p v-if="value.finish_status"><i>完成时间：</i>{{value.finish_time}}</p>
        <p v-if="value.finish_status"><i>完成人：</i>{{value.finish_user_id}}</p>
        <p><i>反馈状态：</i><span>{{value.statusText}}</span></p>
        <p><i>反馈内容：</i>{{value.comment}}</p>
        <!-- <p>提交时间：2020.03.20</p> -->
        <p><i>反馈图：</i><van-image :src="value.photo"></van-image></p>
      </div>
    </div>
    <van-popup v-model="showLayer" position="bottom" safe-area-inset-bottom>
      <service-info @cancel="showLayer = false" @submit="submit"></service-info>
    </van-popup>
  </div>
</template>

<script>
  import NavBar from '@/components/NavBar.vue';
  import ServiceInfo from '@/views/ServiceInfo.vue';
  import CommonService from '@/io/common.js';

  export default {
    name: 'Service',
    data() {
      return {
        showLayer: false,
        datas: []
      }
    },
    mounted() {
      this.initPage();
    },
    components: {
      NavBar,
      ServiceInfo
    },
    methods: {
      returnFunc() {
        this.$router.push({
          path: '/user'
        });
      },
      initPage() {
        this.$toast.loading({
          message: '加载中...',
          duration: 0
        });
        CommonService.serviceList().then(res => {
          this.datas = res.data.map(val => {
            val.photo = `${process.env.VUE_APP_IMAGE_URL}${val.photo}`;
            val.statusText = val.status === '1' ? '未处理' : (val.status === '2' ? '已阅读' : (val.status === '3' ? '同意' : '拒绝'));
            val.finish_status = val.finish_status * 1;
            return val;
          });
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      submit() {
        this.showLayer = false;
        this.initPage();
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .service {
    background-color: #F6F7F9;
    .iosBottom;
    .nav-bar {
      background-color: initial;
      .right-region {
        p {
          background-color: @color-primary;
          color: #FFFFFF;
          font-size: 22 * @r;
          width: 212 * @r;
          height: 42 * @r;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 21 * @r;
        }
      }
    }
    .item {
      background-color: #FFFFFF;
      margin: 28 * @r 44 * @r;
      padding: 40 * @r 54 * @r;
      border-radius: 16 * @r;
      p {
        font-size: 26 * @r;
        display: flex;
        & + p {
          margin-top: 18 * @r;
        }
        i {
          width: 130 * @r;
        }
        span {
          color: #FDAB02;
        }
        &:last-of-type {
          /deep/ .van-image {
            flex: 1;
            height: 452 * @r;
            border-radius: 8 * @r;
            img {
              border-radius: 8 * @r;
            }
          }
        }
      }
    }
  }
  .van-popup {
    height: 100%;
  }
</style>