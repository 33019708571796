import Axios from "axios";
import Store from '@/store';

export default {

  checkRegister() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/checkRegister.html`, {
      params: {
        ...Store.state.reqParams
      }
    });
  },

  initWxMini(
    code
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Weixin/miniLogin`, {
      params: {
        code
      }
    });
  },

  getWxParams() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Api/getJsApiParam`, {
      params: {
        url: window.location.href
      }
    });
  },

  getReqParams(
    code
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Weixin/getAccessToken`, {
      params: {
        code
      }
    });
  },

  allInfo() {
    return Store.state.allInfo ? new Promise((resolve, reject) => {
      resolve(JSON.parse(JSON.stringify(Store.state.allInfo)));
    }) : Axios.get(`${process.env.VUE_APP_DOMAIN}/Community/shop`, {
      params: {
        community_id: 2968,
        open_level: 1,
        ...Store.state.reqParams
      }
    }).then(res => {
      Store.commit('setAllInfo', JSON.parse(JSON.stringify(res)));
      Store.commit('setCartTotalPrice', res.cart.total_price);
      return JSON.parse(JSON.stringify(res));
    }).catch(err => {});
  },

  addCart(
    merchantid,
    goods_id
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/addToCart`, {
      params: {
        merchantid,
        goods_id,
        ...Store.state.reqParams
      }
    });
  },

  removeCart(
    merchantid,
    goods_id
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/removeToCart`, {
      params: {
        merchantid,
        goods_id,
        ...Store.state.reqParams
      }
    });
  },

  addressList() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/userInfoList`, {
      params: {
        show_all: 1,
        community_id: 2968,
        ...Store.state.reqParams
      }
    });
  },

  sendCode(
    phone_num
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/sendVerifyCode`, {
      params: {
        phone_num,
        ...Store.state.reqParams
      }
    });
  },

  addAddress(
    realname,
    gender,
    phone_num,
    verify_code,
    street_address
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/addUserInfo`, {
      params: {
        'userinfo[realname]': realname,
        'userinfo[gender]': gender,
        'userinfo[phone_num]': phone_num,
        'userinfo[verify_code]': verify_code,
        'userinfo[street_address]': street_address,
        'userinfo[community_id]': 2968,
        ...Store.state.reqParams
      }
    });
  },

  search(
    keyword,
    qrcode,
    merchantid
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Community/searchGoods`, {
      params: {
        keyword,
        qrcode,
        merchantid
      }
    });
  },
  
  orderAddress() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/countUserInfoList`, {
      params: {
        community_id: 2968,
        show_all: 0,
        ...Store.state.reqParams
      }
    });
  },

  userInfo(
    userinfo_id,
    merchantid,
    add_extend_order_id
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/getUserInfo`, {
      params: {
        userinfo_id,
        merchantid,
        add_extend_order_id,
        ...Store.state.reqParams
      }
    });
  },

  cartInfo(
    merchantid,
    add_extend_order_id
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/getCart`, {
      params: {
        merchantid,
        add_extend_order_id,
        show_present: 1,
        multi_coupon: 1,
        ...Store.state.reqParams
      }
    });
  },

  createOrder(
    merchantid,
    userinfo_id,
    pay_type,
    user_coupon_ids,
    add_extend_order_id,
    is_mini
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Order/generateOrder`, {
      params: {
        merchantid,
        userinfo_id,
        pay_type,
        user_coupon_ids,
        add_extend_order_id,
        is_mini,
        ...Store.state.reqParams,
        send_time: 0,
        multi_coupon: 1
      }
    });
  },

  emptyCart(
    merchantid
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/clearCart`, {
      params: {
        merchantid,
        ...Store.state.reqParams
      }
    });
  },

  orderList(
    merchantid
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Order/orderList`, {
      params: {
        merchantid,
        ...Store.state.reqParams
      }
    });
  },

  orderListInfo(
    order_id,
    merchantid
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Order/getOrder`, {
      params: {
        order_id,
        merchantid,
        ...Store.state.reqParams
      }
    });
  },

  serviceUpload(
    media_id
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/ShopFeedback/newUploadImg`, {
      params: {
        media_id
      }
    });
  },

  serviceAddress() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/ShopFeedback/newShowAddressList`, {
      params: {
        ...Store.state.reqParams
      }
    })
  },

  addService(
    manufacture_date,
    distribution_id,
    name,
    phone_num,
    address,
    userid,
    description,
    photo,
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/ShopFeedback/newAddFeedback`, {
      params: {
        manufacture_date,
        distribution_id,
        name,
        phone_num,
        address,
        userid,
        description,
        photo,
        ...Store.state.reqParams
      }
    });
  },

  serviceList() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/ShopFeedback/newShopFeedbackList`, {
      params: {
        ...Store.state.reqParams
      }
    });
  },

  indexList() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Index/recommentIndex`);
  },

  indexListGoods(
    recomment_cate_id
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Index/recommentIndexGoods`, {
      params: {
        recomment_cate_id
      }
    });
  },

  recommendGoods() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Index/recommentGoods`);
  },

  user() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/userCenter`, {
      params: {
        community_id: 2968,
        open_level: 1,
        add_extend_order_id: 0,
        ...Store.state.reqParams
      }
    });
  },

  userCoupons() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/getUserCoupon`, {
      params: {
        ...Store.state.reqParams
      }
    });
  },

  addSearchHistory(
    keywords
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/UserSearch/addSearch`, {
      params: {
        keywords,
        ...Store.state.reqParams
      }
    });
  },

  searchHistoryList() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/UserSearch/searchHistoryHotGoods`, {
      params: {
        ...Store.state.reqParams
      }
    });
  },

  cartTotalPrice() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/User/sumCartPrice`, {
      params: {
        ...Store.state.reqParams,
        merchantid: 352
      }
    });
  },

  hotGoods() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Index/recommentGoodsIndex`);
  },

  countDown() {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Community/getLimitDiscount`)
  },

  updateOrderAddress(
    order_id,
    userinfo_id
  ) {
    return Axios.get(`${process.env.VUE_APP_DOMAIN}/Order/updateOrderUserDistribution`, {
      params: {
        order_id,
        userinfo_id,
        ...Store.state.reqParams
      }
    })
  }

}