<template>
  <div class="goods app-nav-wrap">
    <nav-bar current-name="商品列表">
      <template #right v-if="$route.query.count_down && countDownTime > 0">
        <van-count-down :time="countDownTime" @finish="countDownFinish">
          <template #default="timeData">
            <div class="count-down">
              <i class="text">{{ formatTime(timeData.days * 24 + timeData.hours) }}</i>
              <i class="colon">:</i>
              <i class="text">{{ formatTime(timeData.minutes) }}</i>
              <i class="colon">:</i>
              <i class="text">{{ formatTime(timeData.seconds) }}</i>
            </div>
          </template>
        </van-count-down>
      </template>
    </nav-bar>
    <div class="app-nav-content">
      <product-list :datas="datas"></product-list>
    </div>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import NavBar from '@/components/NavBar.vue';
  import ProductList from '@/components/ProductList.vue';

  export default {
    name: 'Goods',
    data() {
      return {
        datas: [],
        countDownTime: 0
      }
    },
    components: {
      NavBar,
      ProductList
    },
    mounted () {
      if (this.$route.query.count_down) {
        this.initCountDown();
      }
      this.initDatas();
    },
    methods: {
      countDownFinish() {
        this.$router.replace({
          path: '/'
        });
      },
      initCountDown() {
        return CommonService.countDown().then(response => {
          this.countDownTime = response.data.has_limit_discount ? response.data.discount_last_time * 1000 : 0;
        }).catch(err => {});
      },
      initDatas() {
        this.$toast.loading({
          message: '加载中...',
          duration: 0
        });
        CommonService.allInfo().then(res => {
          let ids = this.$route.query.ids.split(',');
          let datas = [];
          res.goods.forEach(val => {
            if (ids.indexOf(val.id) > -1) {
              let number = 0;
              res.cart.cart.forEach(m => {
                if (m.goods_id === val.id) {
                  number = m.num * 1;
                }
              });
              datas.push({
                ...val,
                merchantId: res.merchant.id,
                image: `${process.env.VUE_APP_IMAGE_URL}${val.image}`,
                number
              });
            }
          });
          this.datas = datas;
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .goods {
    .iosBottom;
    .app-nav-content {
      padding: 40 * @r;
    }
  }
</style>