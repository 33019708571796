<template>
  <div class="wx-blank">

  </div>
</template>

<script>
  import CommonService from '@/io/common.js';

  export default {
    name: 'WxBlank',
    mounted () {
      let code = this.$route.query.code;
      let path = this.$route.query.redirect_url || '';
      if (code) {
        CommonService.getReqParams(code).then(res => {
          this.$store.commit('setReqParams', {
            openid: res.openid,
            token: res.token
          });
          CommonService.getWxParams().then(res => {
            wx.config({
              ...res
            });
            wx.ready(() => {
              wx.hideMenuItems({
                menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:share:facebook', 'menuItem:share:QZone', 'menuItem:copyUrl', 'menuItem:openWithSafari', 'menuItem:openWithQQBrowser', 'menuItem:favorite']
              });
              this.$router.replace({
                path
              });
            });
          }).catch(err => { });
        }).catch(err => {});
      }
    },
  }
</script>

<style lang="less" scoped>

</style>