<template>
  <div class="home">
    <div class="top">
      <van-icon name="scan" @click="scanCode"></van-icon>
      <div class="search" @click="$store.state.showSearchLayer = true">
        <span>搜索商品</span>
        <van-icon name="search"></van-icon>
      </div>
    </div>
    <div class="scroll">
      <div class="panel-1">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(value, index) in slideDatas" :key="index">
            <van-image :src="value"></van-image>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="nav">
        <div v-for="value in navDatas" :key="value.name" @click="navToPage(value)">
          <van-image :src="value.icon"></van-image>
          <span>{{value.name}}</span>
        </div>
      </div>
      <div class="panel-5" v-if="countDownTime > 0">
        <div class="app-title">
          <span>限时促销</span>
          <van-count-down :time="countDownTime" @finish="countDownFinish" ref="countDown">
            <template #default="timeData">
              <div class="count-down">
                <i class="text">{{ formatTime(timeData.days * 24 + timeData.hours) }}</i>
                <i class="colon">:</i>
                <i class="text">{{ formatTime(timeData.minutes) }}</i>
                <i class="colon">:</i>
                <i class="text">{{ formatTime(timeData.seconds) }}</i>
              </div>
            </template>
          </van-count-down>
        </div>
        <div class="content" @click="goCountDownPage">
          <div v-for="(value, index) in countDownDatas" :key="index">
            <van-image :src="value.image"></van-image>
            <p>
              <span>￥{{value.price}}</span>
              <span v-if="value.old_price">￥{{value.old_price}}</span>
            </p>
          </div>
        </div>
      </div>
      <template v-for="(value, index) in datas">
        <div class="panel-2" :key="index" v-if="value.items.length === 3">
          <div class="app-title">
            <span>{{value.title}}</span>
          </div>
          <div class="content">
            <van-image :src="value.items[0].image" @click="goGoods(value.items[0].id)"></van-image>
            <div class="right-region">
              <van-image :src="value.items[1].image" @click="goGoods(value.items[1].id)"></van-image>
              <van-image :src="value.items[2].image" @click="goGoods(value.items[2].id)"></van-image>
            </div>
          </div>
        </div>
        <div class="panel-3" :key="index" v-else>
          <div class="app-title">
            <span>{{value.title}}</span>
            <!-- <span>更多<van-icon name="arrow"></van-icon></span> -->
          </div>
          <div class="content">
            <van-image v-for="(m, n) in value.items" :key="n" :src="m.image" @click="goGoods(m.id)"></van-image>
          </div>
        </div>
      </template>
      <div class="panel-4" v-if="hotDatas.length">
        <div class="app-title">
          <span>热销商品</span>
          <span @click="goGoodsPage">更多<van-icon name="arrow"></van-icon></span>
        </div>
        <product-list :datas="hotDatas.slice(0, 5)"></product-list>
      </div>
    </div>
    <van-overlay :show="showLayer" :lock-scroll="false" :z-index="2">
      <div class="layer">
        <div class="text-1" v-html="pageTips"></div>
        <div class="btn" @click="showLayer = false">去使用</div>
        <div class="text-2" @click="$router.push({ name: 'Coupon' })">查看我的优惠券</div>
        <van-icon name="clear" @click="showLayer = false" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import ProductList from '@/components/ProductList.vue';

  export default {
    name: 'Home',
    data() {
      return {
        showLayer: false,
        navDatas: [],
        slideDatas: [],
        datas: [],
        hotDatas: [],
        countDownTime: 0,
        countDownDatas: [],
        merchantId: null,
        pageTips: null,
        isFirstRender: this.$store.state.allInfo
      }
    },
    components: {
      ProductList
    },
    mounted() {
      this.$toast.loading({
        message: '加载中...',
        duration: 0
      });
      wx.miniProgram.getEnv(res => {
        if (res.miniprogram) {
          CommonService.checkRegister().then(res => {
            if (!res.is_register) {
              wx.miniProgram.redirectTo({url: `/pages/register/register?token=${this.$store.state.reqParams.token}&openid=${this.$store.state.reqParams.openid}`});
            }
          });
        }
      });
      this.initPage().then(res => {
        return Promise.all([
          this.initCountDown(res),
          this.initDatas(),
          this.initHotDatas(res)
        ]);
      }).then(() => {
        if (this.pageTips && !this.isFirstRender) {
          this.showLayer = true;
        }
      }).catch(err => {}).finally(() => {
        this.$toast.clear();
      });
    },
    methods: {
      navToPage(data) {
        if (data.type === 1) {
          this.$router.push({
            path: data.uri
          });
        } else {
          window.location.href = data.uri;
        }
      },
      countDownFinish() {
        this.initPage().then(res => {
          this.initCountDown(res);
        });
      },
      initPage() {
        return CommonService.allInfo().then(res => {
          this.merchantId = res.merchant.id;
          this.slideDatas = res.carousel.map(val => `${process.env.VUE_APP_IMAGE_URL}${val}`);
          this.pageTips = res.page_tips;
          this.navDatas = res.navbar;
          return res;
        }).catch(err => {});
      },
      initCountDown(res) {
        return CommonService.countDown().then(response => {
          this.countDownTime = response.data.has_limit_discount ? response.data.discount_last_time * 1000 : 0;
          this.countDownDatas = this.formatDatas(response.data.discount_goods, res);
        }).catch(err => {});
      },
      goGoodsPage() {
        this.$router.push({
          path: `/goods?ids=${this.hotDatas.map(val => val.id).join(',')}`
        });
      },
      initHotDatas(res) {
        return CommonService.hotGoods().then(response => {
          this.hotDatas = this.formatDatas(response.data, res);
        });
      },
      formatDatas(sourceDatas, datas) {
        return sourceDatas.map(val => {
          let data;
          let number = 0;
          datas.cart.cart.map(m => {
            if (val.goods_id === m.goods_id) {
              number = m.num * 1;
            }
          });
          datas.goods.forEach(m => {
            if (val.goods_id === m.id) {
              data = {
                ...m,
                number,
                merchantId: this.merchantId,
                image: `${process.env.VUE_APP_IMAGE_URL}${m.image}`,
              }
            }
          });
          return data;
        });
      },
      initDatas() {
        return CommonService.indexList().then(res => {
          this.datas = res.data.map(val => {
            val.items.map(m => {
              m.image = `${process.env.VUE_APP_IMAGE_URL}${m.recomment_cate_thumb}`;
              return m;
            });
            return val;
          });
        }).catch(err => {});
      },
      goGoods(id) {
        this.$toast.loading({
          message: '加载中...',
          duration: 0
        });
        CommonService.indexListGoods(id).then(res => {
          this.$router.push({
            path: `/goods?ids=${res.data.map(val => val.goods_id).join(',')}`
          });
        }).catch(err => {}).finally(() => {
          this.$toast.clear();
        });
      },
      goCountDownPage() {
        this.$router.push({
          path: `/goods?ids=${this.countDownDatas.map(val => val.id).join(',')}&count_down=true`
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .home {
    .top {
      display: flex;
      align-items: center;
      margin: 34 * @r 0;
      padding: 0 40 * @r;
      & > .van-icon {
        font-size: 40 * @r;
        margin-right: 46 * @r;
        color: #696969;
      }
      .search {
        height: 56 * @r;
        background-color: #F3F4F7;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 28 * @r;
        color: #959AA3;
        span {
          font-size: 24 * @r;
        }
        .van-icon {
          font-size: 36 * @r;
          position: absolute;
          right: 30 * @r;
        }
      }
    }
    .nav {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 30 * @r;
      margin-bottom: 32 * @r;
      & > div {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10 * @r 0;
        .van-image {
          width: 100 * @r;
          margin-bottom: 10 * @r;
        }
        span {
          font-size: 24 * @r;
        }
      }
    }
    .panel-1 {
      margin-bottom: 42 * @r;
      padding: 0 30 * @r;
    }
    .panel-2,
    .panel-3,
    .panel-4,
    .panel-5 {
      padding: 0 40 * @r;
    }
    .panel-2 {
      margin-bottom: 42 * @r;
      .content {
        display: flex;
        /deep/ .van-image {
          border-radius: 8 * @r;
          img {
            border-radius: 8 * @r;
          }
        }
        & > .van-image {
          width: 50%;
          height: 336 * @r;
        }
        .right-region {
          width: 50%;
          margin-left: 14 * @r;
          .van-image {
            height: 160 * @r;
            & + .van-image {
              margin-top: 14 * @r;
            }
          }
        }
      }
    }
    .panel-3 {
      margin-bottom: 42 * @r;
      .content {
        display: flex;
        justify-content: space-between;
        /deep/ .van-image {
          flex: 1;
          box-shadow: 0 2 * @r 24 * @r 0 rgba(0, 0, 0, .1);
          height: 210 * @r;
          border-radius: 16 * @r;
          & + .van-image {
            margin-left: 16 * @r;
          }
          img {
            border-radius: 16 * @r;
          }
        }
      }
    }
    .panel-4 {
      margin-bottom: 42 * @r;
    }
    .panel-5 {
      margin-bottom: 42 * @r;
      .content {
        display: flex;
        overflow: auto;
        & > div {
          flex: 1;
          justify-content: space-between;
          // box-shadow: 0 2 * @r 24 * @r 0 rgba(0, 0, 0, .1);
          padding: 8 * @r;
          border-radius: 8 * @r;
          .van-image {
            height: 210 * @r;
            margin-bottom: 8 * @r;
          }
          p {
            font-size: 20 * @r;
            text-align: center;
            span {
              margin: 0 4 * @r;
              &:nth-of-type(1) {
                color: @color-primary;
              }
              &:nth-of-type(2) {
                opacity: .4;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
  /deep/ .van-swipe {
    height: 210 * @r;
    .van-swipe__track {
      .van-swipe-item {
        .van-image {
          height: 100%;
          border-radius: 16 * @r;
          margin: 0 10 * @r;
          img {
            border-radius: 16 * @r;
          }
        }
      }
    }
    .van-swipe__indicators {
      left: 36 * @r;
    }
  }
  .scroll {
    height: calc(100% - 124 * @r);
    overflow: auto;
  }
  .layer {
    .setImg('/dialog.png');
    width: 500 * @r;
    height: 586 * @r;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    .text-1 {
      font-size: 36 * @r;
      line-height: 50 * @r;
      text-align: center;
      margin: 100 * @r 0;
    }
    .btn {
      width: 180 * @r;
      height: 60  * @r;
      font-size: 28 * @r;
      background-color: #78b65f;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30 * @r;
      margin: 0 auto 125 * @r auto;
    }
    .text-2 {
      font-size: 28 * @r;
      text-align: center;
      color: #FFFFFF;
    }
    .van-icon {
      position: absolute;
      top: -30 * @r;
      right: 0;
      font-size: 60 * @r;
    }
  }
</style>