<template>
  <div class="user">
    <div class="panel-1">
      <van-image :src="panelOneData.image" v-if="panelOneData.image"></van-image>
      <van-icon name="https://b.yzcdn.cn/vant/icon-demo-1126.png" v-else/>
      <div class="right-region">
        <p>{{panelOneData.name}}<span>{{panelOneData.phone}}</span></p>
        <p>{{panelOneData.address}}</p>
      </div>
    </div>
    <div class="panel-2">
      <div class="app-title">
        <span>业代信息</span>
      </div>
      <div class="items">
        <p>业代姓名：{{panelTwoData.name}}</p>
        <!-- <p>服务时间：2020.2.3</p> -->
        <p><a href="tel:10086">400电话：{{panelTwoData.tel}}</a></p>
        <p><a href="tel:10086">业代电话：{{panelTwoData.phone}}</a></p>
      </div>
    </div>
    <div class="panel-3">
      <div class="app-title">
        <span>本月订单金额与优惠信息</span>
      </div>
      <p>本月订单金额：<span>{{panelThreeData.numberOne}}</span>元</p>
      <p>使用优惠券：共为您节省<span>{{panelThreeData.numberTwo}}</span>元</p>
    </div>
    <div class="panel-4">
      <p @click="goPage('/address')"><span></span>收货地址</p>
      <p @click="goPage('/coupon')"><span></span>优惠券</p>
      <p @click="goPage('/order')"><span></span>我的订单</p>
      <p @click="goPage(`/goods?ids=${ids}`)"><span></span>常购清单</p>
      <p @click="goPage('/service')"><span></span>服务反馈</p>
    </div>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';

  export default {
    name: 'User',
    data() {
      return {
        panelOneData: {},
        panelTwoData: {},
        panelThreeData: {},
        ids: null
      }
    },
    mounted () {
      this.$toast.loading({
        message: '加载中...',
        duration: 0
      });
      Promise.all([
        this.initInfo(),
        this.initDatas()
      ]).finally(() => {
        this.$toast.clear();
      });
    },
    methods: {
      initInfo() {
        return CommonService.user().then(res => {
          this.panelOneData = {
            image: res.data.user.headimgurl,
            name: res.data.userinfo.name,
            phone: res.data.userinfo.phone_num,
            address: res.data.userinfo.address
          }
          this.panelTwoData = {
            name: res.data.salesinfo.qy_user.name,
            tel: res.data.salesinfo.customer_service_tel,
            phone: res.data.salesinfo.qy_user.mobile
          }
          this.panelThreeData = {
            numberOne: this.divideNumber(res.data.order.total, 100),
            numberTwo: this.divideNumber(res.data.order.discount, 100)
          }
        }).catch(err => {});
      },
      initDatas() {
        return CommonService.allInfo().then(res => {
          this.ids = res.recent_goods_id.join(',');
        }).catch(err => {});
      },
      goPage(path) {
        this.$router.push({
          path
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../assets/styles/tools.less';
  .user {
    background-color: #F6F7F9;
    .panel-1 {
      display: flex;
      align-items: center;
      padding: 34 * @r;
      & > .van-icon {
        margin-right: 34 * @r;
        img {
          width: 90 * @r;
          height: 90 * @r;
        }
      }
      /deep/ .van-image {
        border: 1px solid #979797;
        border-radius: 50%;
        width: 90 * @r;
        height: 90 * @r;
        margin-right: 34 * @r;
        img {
          border-radius: 50%;
        }
      }
      .right-region {
        p {
          &:nth-of-type(1) {
            font-size: 36 * @r;
            span {
              font-size: 24 * @r;
              color: #82858B;
              margin-left: 20 * @r;
            }
          }
          &:nth-of-type(2) {
            font-size: 24 * @r;
            color: #82858B;
          }
        }
      }
    }
    .panel-2 {
      background-color: #FFFFFF;
      padding: 32 * @r;
      margin: 0 26 * @r 22 * @r 26 * @r;
      border-radius: 26 * @r;
      .items {
        display: flex;
        flex-wrap: wrap;
        margin: -8 * @r 0;
        padding: 0 12 * @r;
        p {
          width: 50%;
          font-size: 26 * @r;
          margin: 8 * @r 0;
        }
      }
    }
    .panel-3 {
      background-color: #FFFFFF;
      padding: 32 * @r;
      margin: 0 26 * @r 26 * @r 26 * @r;
      border-radius: 26 * @r;
      p {
        font-size: 26 * @r;
        padding: 0 12 * @r;
        & + p {
          margin-top: 14 * @r;
        }
        span {
          color: @color-primary;
          font-size: 36 * @r;
          margin: 0 10 * @r;
        }
      }
    }
    .panel-4 {
      background-color: #FFFFFF;
      padding: 0 32 * @r;
      margin: 0 26 * @r 26 * @r 26 * @r;
      border-radius: 26 * @r;
      p {
        font-size: 28 * @r;
        font-weight: bold;
        height: 116 * @r;
        border-bottom: 1px solid #EBEBEB;
        display: flex;
        align-items: center;
        padding: 0 12 * @r;
        &:last-of-type {
          border: none;
        }
        &:nth-of-type(1) {
          span {
            &::before {
              .setImg('/user-icon-1.png');
            }
          }
        }
        &:nth-of-type(2) {
          span {
            &::before {
              .setImg('/user-icon-2.png');
            }
          }
        }
        &:nth-of-type(3) {
          span {
            &::before {
              .setImg('/user-icon-3.png');
            }
          }
        }
        &:nth-of-type(4) {
          span {
            &::before {
              .setImg('/user-icon-4.png');
            }
          }
        }
        &:nth-of-type(5) {
          span {
            &::before {
              .setImg('/user-icon-5.png');
            }
          }
        }
        span {
          margin-right: 16 * @r;
          width: 54 * @r;
          height: 54 * @r;
          display: flex;
          align-items: center;
          justify-content: center;
          &::before {
            content: '';
          }
        }
      }
    }
  }
</style>