<template>
  <div id="app">
    <router-view></router-view>
    <van-popup class="search-van-popup" v-model="$store.state.showSearchLayer" position="bottom" safe-area-inset-bottom>
      <search></search>
    </van-popup>
  </div>
</template>

<script>
  import CommonService from '@/io/common.js';
  import Search from '@/views/Search.vue';

  export default {
    name: 'App',
    components: {
      Search,
    }
  }
</script>

<style lang="less">
  @import './assets/styles/base.less';
  @import './assets/styles/tabbar.less';
  @import './assets/styles/image.less';
  @import './assets/styles/swipe.less';
  @import './assets/styles/checkbox.less';
  @import './assets/styles/radio.less';
  @import './assets/styles/form.less';
  @import './assets/styles/stepper.less';
  .search-van-popup {
    height: 100%;
  }
</style>
